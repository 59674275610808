import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  Box,
  Button,
  Container, createTheme,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  TextField, ThemeProvider,
  Typography
} from '@mui/material';
import {coreMatrix} from "../utils/productivity";
import CalcOutput from "../components/CalcOutput";
import LogoIcon from '../assets/logo.svg';
import {ReactSVG} from "react-svg";

interface FormData {
    companyType: string;
    hireType: string;
    recruitersCount: number;
    recruiterCost: number;
    contractLength: number;
    feesPerHire: number;
    placementsPerMonth: number;
    aiReason: string;
    paymentPerRecruiter: number;
    netProfit: number;
    costPerMonth: number;
    jobTalkCostPerRecruiter: number;
}

// Create a custom theme with Montserrat font
const theme = createTheme({
    palette: {
        primary: {
            main: '#ff7043',
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        body1: {
            fontWeight: 500,  // Set body1 to bold
        },
    },
    components: {
        MuiSlider: {
            styleOverrides: {
                root: {
                    height: 8, // Change this value for overall thickness
                },
                thumb: {
                    height: 14, // Thumb height
                    width: 14, // Thumb width
                    // You can customize thumb styles here
                },
                track: {
                    height: 6, // Track thickness
                    borderRadius: 6,
                },
                rail: {
                    height: 6, // Rail thickness
                    borderRadius: 6,
                    backgroundColor: '#b5b5b5',
                },
            },
        },
    },
});

const CalcPage: React.FC = () => {
    const {
        register, reset, setValue
    } = useForm<FormData>();

    const [yearList, setYearList] = useState([]);
    const [formData, setFormData] = useState<FormData>({
        companyType: '',
        hireType: 'contractors',
        recruitersCount: 0,
        recruiterCost: 0,
        contractLength: 0,
        feesPerHire: 0,
        placementsPerMonth: 0,
        aiReason: '',
        paymentPerRecruiter: 0,
        netProfit: 0,
        costPerMonth: 0,
        jobTalkCostPerRecruiter: 500,
    });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [formReset, setFormReset] = useState(true);
    const [validationError, setValidationError] = useState<any>({
        recruitersCount: '',
        recruiterCost: '',
        costPerMonth: '',
        feesPerHire: '',
        placementsPerMonth: '',
        paymentPerRecruiter: '',
        contractLength: '',
        netProfit: '',
        jobTalkCostPerRecruiter: ''
    });

    useEffect(() => {
        if (formData?.hireType === 'fulltime') {
            setFormData((prevFieldValue) => ({...prevFieldValue, companyType: 'employer', aiReason: 'increaseRevenue'}));
        } else {
            setFormData((prevFieldValue) => ({...prevFieldValue, companyType: 'staffing'}));
        }
    }, [formData?.hireType]);

    // Functions for calculations
    const increasePlacementCalc = (item: any, data: any) => {
        return item.increaseInProduction * Number(data.recruitersCount)
    }
    const reducedRecCalc = (item: any, data: any) => {
        return Number(data.recruitersCount) - Math.floor(Number(data.recruitersCount) * (Number(item.reductionInRecruiters) / 100))
    }
    const increaseProfitAddedCalc = (item: any, oneContractor: any) => {
        return Number(oneContractor) * (Number(item.reducedRec) * Number(item.increaseInProduction));
    }
    const recruitersCostSavingsCalc = (item: any, data: any) => {
        return Number(Number(data.recruitersCount) - Number(item.reducedRec)) * Number(data.costPerMonth)
    }
    const increaseProfitCalc = (item: any, oneContractor: any) => {
        return item.increasePlacement * Number(oneContractor);
    }

    const calculateData = useCallback((data: any) => {
        setFormData(data);
        let totalValueOfOneContractor = 0;

        if (formData?.hireType === 'contractors') {
            totalValueOfOneContractor = Number(data.contractLength) * Number(data.netProfit) * 160;
        } else {
            totalValueOfOneContractor = Number(formData?.feesPerHire);
        }

        coreMatrix.map((item: any) => {
            item.increasePlacement = increasePlacementCalc(item, data);
            if (formData?.aiReason === 'reduceCost' && formData?.hireType === 'contractors') {
                item.reducedRec = reducedRecCalc(item, data);
                item.increaseProfitAdded = increaseProfitAddedCalc(item, totalValueOfOneContractor);
                item.recruitersCostSavings = recruitersCostSavingsCalc(item, data);
            } else {
                item.increaseProfitAdded = increaseProfitCalc(item, totalValueOfOneContractor);
            }
            return item;
        });

        const yearData: any = [];
        for (let i = 0; i < coreMatrix.length; i += 12) {
            yearData.push(coreMatrix.slice(i, i + 12));
        }

        yearData.map((monthList: any) => {
            if (!monthList.totalExtraProfit) {
                monthList.totalExtraProfit = 0;
            }
            if (!monthList.increasePlacement) {
                monthList.increasePlacement = 0;
            }
            if (!monthList.recruiterCount) {
                monthList.recruiterCount = 0;
            }
            if (!monthList.costPerMonth) {
                monthList.costPerMonth = 0;
            }
            if (!monthList.recruitersCostSavings) {
                monthList.recruitersCostSavings = 0;
            }

            monthList.map((item: any) => {
                monthList.totalExtraProfit += item.increaseProfitAdded;
                monthList.increasePlacement += item.increasePlacement;
                monthList.recruitersCostSavings += item.recruitersCostSavings;
                monthList.recruiterCount = Number(data.recruitersCount);
                monthList.costPerMonth = data.recruitersCount * data.costPerMonth;
                return item;
            });

            return monthList;
        });

        setYearList(yearData);
    }, [formData, setFormData, setYearList]);

    const onSubmit = useCallback((data: FormData) => {
        setFormReset(false);
        setFormData(data);

        const isValidationErrorEmpty = Object.values(validationError).every(value => value === '' || value === null);

        if (isValidationErrorEmpty) {
            setIsFormSubmitted(true);
        }

        calculateData(data);
    }, [setFormReset, setFormData, validationError, setIsFormSubmitted, calculateData]);

    useEffect(() => {
        const {
            hireType,
            recruitersCount,
            costPerMonth,
            placementsPerMonth,
            aiReason,
            jobTalkCostPerRecruiter,
            netProfit,
            contractLength,
            feesPerHire
        } = formData;

        const commonFields = [hireType, recruitersCount, costPerMonth, placementsPerMonth, aiReason, jobTalkCostPerRecruiter];

        let requiredFields: any;

        if (hireType === "contractors") {
            requiredFields = [...commonFields, netProfit, contractLength];
        } else if (hireType === "fulltime") {
            requiredFields = [...commonFields, feesPerHire];
        }

        const allFieldsFilled = requiredFields?.every((field: any) => field !== undefined && field !== null && field !== '' && field !== 0);

        if (allFieldsFilled) {
            onSubmit(formData);
        }
    }, [formData, onSubmit]);

    const checkValidation = (value: any, allowDecimals: boolean = false, name: any): string | null => {
        if (value === '') {
            return 'This field is required';
        }

        switch (name) {
            case 'recruitersCount':
                if (value <= 0 || value > 1000) {
                    return 'Value must be between 0 and 1000';
                }
                break;

            case 'costPerMonth':
                if (value <= 0 || value > 5000) {
                    return 'Value must be between 0 and 5000';
                }
                break;

            case 'contractLength':
                if (value <= 0 || value > 24) {
                    return 'Value must be between 0 and 24';
                }
                break;

            case 'netProfit':
                if (allowDecimals) {
                    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                        return 'Please enter a number with up to 2 decimals';
                    }
                }
                if (value <= 0 || value > 100) {
                    return 'Value must be between 0 and 100';
                }
                break;

            case 'feesPerHire':
                if (value <= 0 || value > 10000) {
                    return 'Value must be between 0 and 10000';
                }
                break;

            case 'placementsPerMonth':
                if (value <= 0 || value > 100) {
                    return 'Value must be between 0 and 100';
                }
                break;

            case 'jobTalkCostPerRecruiter':
                if (value <= 180 || value > 2000) {
                    return 'Value must be between 180 and 2000';
                }
                break;

            default:
                return null;
        }
        return null;
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value}: any = event.target;
        setFormData((prevFieldValue) => ({...prevFieldValue, [name]: value}));

        let errorMessage: string | null = null;
        if (['costPerMonth', 'contractLength', 'jobTalkCostPerRecruiter', 'recruitersCount', 'feesPerHire', 'placementsPerMonth'].includes(name)) {
            errorMessage = checkValidation(value, false, name);
        } else if (name === 'netProfit') {
            errorMessage = checkValidation(value, true, name);
        }

        setValidationError((prevErrors: any) => ({...prevErrors, [name]: errorMessage}));

        setFormData((prevFormData) => ({...prevFormData, [name]: value}) as FormData);


        if (name === 'hireType') {
            setIsFormSubmitted(false);
        }

        if (isFormSubmitted) {
            calculateData({...formData, [name]: value} as FormData);
        }
    };

    const resetForm = () => {
        setIsFormSubmitted(false);
        setFormData({
            ...formData,
            hireType: 'contractors',
            recruitersCount: 0,
            costPerMonth: 0,
            contractLength: 0,
            netProfit: 0,
            feesPerHire: 0,
            placementsPerMonth: 0,
            jobTalkCostPerRecruiter: 500,
            aiReason: ''
        })
        reset();
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="flex flex-col pt-5 md:pt-0 items-center h-screen font-sans">
                <Container maxWidth={false} className="w-full bg-slate-900 p-5">
                    <div className={'flex justify-between'}>
                        <div className="flex items-center gap-1.5">
                            <ReactSVG
                                src={LogoIcon}
                                className={'w-7 h-7 [&_.primary-color]:fill-primary-dark [&_.secondary-color]:fill-primary-light'}
                            />
                            <span className="text-white font-light">JobTalk</span>
                        </div>
                        <div>
                            <span className="text-white text-xl">Calculate Your Savings With AI Recruiting</span>
                        </div>
                    </div>
                </Container>

                <div className={`flex w-full h-full items-center align-middle flex-wrap p-3 p-lg-6 overflow-x-hidden ${isFormSubmitted ? '' : 'justify-center'}`}>
                    <div className={`w-full lg:w-[30%] h-fit  ${formReset ? ' ' : `${!isFormSubmitted ? 'lg:animate-moveFormAtLeft' : isFormSubmitted && 'lg:animate-moveLeft'}`} `}>
                        <Box sx={{borderRadius: 2, padding: 3}}
                             className={`w-full bg-gray-50 border border-slack-500 p-2 p-lg-5 h-max`}>

                            <div className="w-full flex justify-between flex-wrap">
                                <RadioGroup
                                    {...register('hireType', {required: true})}
                                    onChange={(e) => handleInputChange(e)}
                                    sx={{display: 'flex', flexDirection: "unset", gap: '10px',}}
                                >
                                    <FormControlLabel
                                        value="contractors"
                                        control={<Radio/>}
                                        checked={formData?.hireType === 'contractors'}
                                        label="Contractors"
                                        sx={{color: 'darkslategray', marginRight: '0',}}
                                    />
                                    <FormControlLabel
                                        value="fulltime"
                                        control={<Radio/>}
                                        checked={formData?.hireType === 'fulltime'}
                                        label="Direct Hire"
                                        sx={{color: 'darkslategray', marginRight: '0',}}
                                    />
                                </RadioGroup>
                                <Button color="warning" variant="contained" className={'mt-0 w-2/12'}
                                        onClick={resetForm}>
                                    Reset
                                </Button>
                            </div>

                            {(formData?.hireType === 'contractors' || formData?.hireType === 'fulltime') && (
                                <div>
                                    <div className='my-3'>
                                        <Divider/>
                                    </div>
                                    <Grid container>
                                        <Grid item xs={12} md={10}>
                                            <Typography variant="body1" color="darkslategray">
                                                Total recruiters
                                            </Typography>
                                            <Slider
                                                size="small"
                                                defaultValue={formData.recruitersCount}
                                                aria-label="Small"
                                                valueLabelDisplay="auto"
                                                onChange={(e, value: any) => {
                                                    setFormData((prevFieldValue) => ({
                                                        ...prevFieldValue,
                                                        recruitersCount: value
                                                    }));
                                                    if (value) {
                                                        setValidationError((prevErrors: any) => ({...prevErrors, recruitersCount: ''}));
                                                    }
                                                }}
                                                value={formData.recruitersCount}
                                                min={0}
                                                max={1000}
                                                tabIndex={-1}
                                            />

                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                                <TextField
                                                    className={'input-number-no-arrow'}
                                                    size="small"
                                                    // value={formData.recruitersCount}
                                                    value={formData.recruitersCount !== 0 ? formData.recruitersCount : ''}
                                                    fullWidth
                                                    placeholder="0"
                                                    {...register('recruitersCount', {required: true})}
                                                    InputProps={{
                                                        type: 'number',
                                                        inputProps: {
                                                            min: 0,
                                                            max: 1000,
                                                            style: {textAlign: 'right'},
                                                            pattern: '^[0-9]+$'
                                                        },
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === '+' || e.key === '-') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onBlur={(e: any) => {
                                                        let value = parseInt(e.target.value);
                                                        if (value < 0 || !value) {
                                                            setValue('recruitersCount', 0)
                                                        } else if (value > 1000) {
                                                            setValue('recruitersCount', 1000)
                                                        }
                                                        if (value !== 0) {
                                                            const trimmedValue = parseInt(e.target.value.replace(/^0+/g, ''));
                                                            setValue('recruitersCount', trimmedValue);
                                                        }
                                                        handleInputChange(e);
                                                    }}
                                                    error={!!validationError.recruitersCount}
                                                    helperText={validationError.recruitersCount}
                                                    onChange={handleInputChange}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            fontSize: '0.8rem'
                                                        }
                                                    }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={10}>
                                            <Typography variant="body1" color="darkslategray">
                                                Average cost per month of each recruiter?
                                            </Typography>
                                            <Slider
                                                size="small"
                                                defaultValue={formData.costPerMonth}
                                                aria-label="Small"
                                                valueLabelDisplay="auto"
                                                onChange={(e, value: any) => {
                                                    setFormData((prevFieldValue) => ({
                                                        ...prevFieldValue,
                                                        'costPerMonth': value
                                                    }));

                                                    if (value) {
                                                        setValidationError((prevErrors: any) => ({...prevErrors, costPerMonth: ''}));
                                                    }
                                                }}
                                                value={formData.costPerMonth}
                                                min={0}
                                                max={5000}
                                                tabIndex={-1}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                className={'input-number-no-arrow'}
                                                size="small"
                                                value={formData.costPerMonth !== 0 ? formData.costPerMonth : ''}
                                                fullWidth
                                                placeholder="0"
                                                {...register('costPerMonth', {
                                                    required: 'This field is required',
                                                    pattern: {
                                                        value: /^\d+(\.\d+)?$/,
                                                        message: 'Please enter a number without decimals'
                                                    }
                                                })}
                                                InputProps={{
                                                    type: 'number',

                                                    inputProps: {
                                                        min: 0,

                                                        max: 5000,
                                                        step: '1',
                                                        style: {textAlign: 'right'},
                                                        pattern: '^[0-9]+$'
                                                    }
                                                }}
                                                onBlur={(e: any) => {
                                                    let value = parseInt(e.target.value);
                                                    if (value < 0 || !value) {
                                                        setValue('costPerMonth', 0)
                                                    } else if (value > 5000) {
                                                        setValue('costPerMonth', 5000)
                                                    }
                                                    if (value !== 0) {
                                                        const trimmedValue = parseInt(e.target.value.replace(/^0+/g, ''));
                                                        setValue('costPerMonth', trimmedValue);
                                                    }
                                                    handleInputChange(e);
                                                }}
                                                onKeyDown={(e: any) => {
                                                    if (e.key === '+' || e.key === '-') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                error={!!validationError.costPerMonth}
                                                helperText={validationError.costPerMonth}
                                                onChange={handleInputChange}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        fontSize: '0.8rem'
                                                    }
                                                }}

                                            />
                                        </Grid>

                                        {formData?.hireType === 'contractors' && <>
                                            <Grid item xs={12} md={10}>
                                                <Typography variant="body1" color="darkslategray">
                                                    Average length of your contractor in months?
                                                </Typography>
                                                <Slider
                                                    size="small"
                                                    defaultValue={formData.contractLength}
                                                    aria-label="Small"
                                                    valueLabelDisplay="auto"
                                                    onChange={(e, value: any) => {
                                                        setFormData((prevFieldValue) => ({
                                                            ...prevFieldValue,
                                                            'contractLength': value
                                                        }));
                                                        if (value) {
                                                            setValidationError((prevErrors: any) => ({...prevErrors, contractLength: ''}));
                                                        }
                                                    }}
                                                    value={formData.contractLength}
                                                    min={0}
                                                    max={24}
                                                    tabIndex={-1}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    className={'input-number-no-arrow'}
                                                    size="small"
                                                    fullWidth
                                                    // value={formData.contractLength}
                                                    value={formData.contractLength !== 0 ? formData.contractLength : ''}
                                                    placeholder="0"
                                                    {...register('contractLength', {required: true})}
                                                    InputProps={{
                                                        type: 'number',
                                                        inputProps: {
                                                            min: 0,
                                                            max: 24,
                                                            style: {textAlign: 'right'},
                                                            pattern: '^[0-9]+$'
                                                        },
                                                    }}
                                                    onBlur={(e: any) => {
                                                        let value = parseInt(e.target.value);
                                                        if (value < 0 || !value) {
                                                            setValue('contractLength', 0)
                                                        } else if (value > 24) {
                                                            setValue('contractLength', 24)
                                                        }
                                                        if (value !== 0) {
                                                            const trimmedValue = parseInt(e.target.value.replace(/^0+/g, ''));
                                                            setValue('contractLength', trimmedValue);
                                                        }
                                                        handleInputChange(e);
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === '+' || e.key === '-') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    error={!!validationError.contractLength}
                                                    helperText={validationError.contractLength}
                                                    onChange={handleInputChange}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            fontSize: '0.8rem'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </>}

                                        {formData?.hireType === 'contractors' && <>
                                            <Grid item xs={12} md={10}>
                                                <Typography variant="body1" color="darkslategray">
                                                    Average net profit per contractor per hr.
                                                </Typography>
                                                <Slider
                                                    size="small"
                                                    defaultValue={formData.netProfit}
                                                    aria-label="Small"
                                                    valueLabelDisplay="auto"
                                                    onChange={(e, value: any) => {
                                                        setFormData((prevFieldValue) => ({
                                                            ...prevFieldValue,
                                                            'netProfit': value
                                                        }));
                                                        if (value) {
                                                            setValidationError((prevErrors: any) => ({...prevErrors, netProfit: ''}));
                                                        }
                                                    }}
                                                    value={formData.netProfit}
                                                    min={0}
                                                    max={100}
                                                    tabIndex={-1}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    className={'input-number-no-arrow'}
                                                    size="small"
                                                    fullWidth
                                                    // value={formData.netProfit}
                                                    value={formData.netProfit !== 0 ? formData.netProfit : ''}
                                                    placeholder="0"
                                                    {...register('netProfit', {required: true})}
                                                    InputProps={{
                                                        type: 'number',
                                                        inputProps: {
                                                            min: 0,
                                                            max: 100,
                                                            style: {textAlign: 'right'},
                                                            pattern: '^[0-9]+$'
                                                        },
                                                    }}
                                                    onBlur={(e: any) => {
                                                        let value = e.target.value;
                                                        if (value < 0 || !value) {
                                                            setValue('netProfit', 0)
                                                        } else if (value > 100) {
                                                            setValue('netProfit', 100)
                                                        } else {
                                                            setValue('netProfit', Math.round(value * 100) / 100);
                                                        }
                                                        handleInputChange(e);
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === '+' || e.key === '-') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    error={!!validationError.netProfit}
                                                    helperText={validationError.netProfit}
                                                    onChange={handleInputChange}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            fontSize: '0.8rem'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </>}

                                        {formData?.hireType !== 'contractors' && <>
                                            <Grid item xs={12} md={10}>
                                                <Typography variant="body1" color="darkslategray">
                                                    Value/Fee/Cost you are averaging per hire?
                                                </Typography>
                                                <Slider
                                                    size="small"
                                                    defaultValue={formData.feesPerHire}
                                                    aria-label="Small"
                                                    onChange={(e, value: any) => {
                                                        setFormData((prevFieldValue) => ({
                                                            ...prevFieldValue,
                                                            'feesPerHire': value
                                                        }));
                                                        if (value) {
                                                            setValidationError((prevErrors: any) => ({...prevErrors, feesPerHire: ''}));
                                                        }
                                                    }}
                                                    max={10000}
                                                    valueLabelDisplay="auto"
                                                    value={formData.feesPerHire}
                                                    min={0}
                                                    tabIndex={-1}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    className={'input-number-no-arrow'}
                                                    size="small"
                                                    // value={formData.feesPerHire}
                                                    value={formData.feesPerHire !== 0 ? formData.feesPerHire : ''}
                                                    fullWidth
                                                    placeholder="0"
                                                    {...register('feesPerHire', {required: true})}
                                                    InputProps={{
                                                        type: 'number',
                                                        inputProps: {
                                                            min: 1,
                                                            max: 10000,
                                                            style: {textAlign: 'right'},
                                                            pattern: '^[0-9]+$',
                                                        },
                                                    }}
                                                    onBlur={(e: any) => {
                                                        let value = parseInt(e.target.value);
                                                        if (value < 0 || !value) {
                                                            setValue('feesPerHire', 0)
                                                        } else if (value > 10000) {
                                                            setValue('feesPerHire', 10000)
                                                        }
                                                        if (value !== 0) {
                                                            const trimmedValue = parseInt(e.target.value.replace(/^0+/g, ''));
                                                            setValue('feesPerHire', trimmedValue);
                                                        }
                                                        handleInputChange(e);
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === '+' || e.key === '-') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    error={!!validationError.feesPerHire}
                                                    helperText={validationError.feesPerHire}
                                                    onChange={handleInputChange}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            fontSize: '0.8rem'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </>}

                                        <Grid item xs={12} md={10}>
                                            <Typography variant="body1" color="darkslategray">
                                                Placements per month each recruiter does?
                                            </Typography>
                                            <Slider
                                                size="small"
                                                defaultValue={formData.placementsPerMonth}
                                                aria-label="Small"
                                                valueLabelDisplay="auto"
                                                onChange={(e, value: any) => {
                                                    setFormData((prevFieldValue) => ({
                                                        ...prevFieldValue,
                                                        'placementsPerMonth': value
                                                    }));
                                                }}
                                                value={formData.placementsPerMonth}
                                                min={0}
                                                max={100}
                                                tabIndex={-1}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                className={'input-number-no-arrow'}
                                                size="small"
                                                fullWidth
                                                // value={formData.placementsPerMonth}
                                                value={formData.placementsPerMonth !== 0 ? formData.placementsPerMonth : ''}
                                                placeholder="0"
                                                {...register('placementsPerMonth', {required: true})}
                                                InputProps={{
                                                    type: 'number',
                                                    inputProps: {
                                                        min: 0,
                                                        max: 100,
                                                        style: {textAlign: 'right'},
                                                        pattern: '^[0-9]+$'
                                                    },
                                                }}
                                                onBlur={(e: any) => {
                                                    let value = parseInt(e.target.value);
                                                    if (value < 0 || !value) {
                                                        setValue('placementsPerMonth', 0)
                                                    } else if (value > 100) {
                                                        setValue('placementsPerMonth', 100)
                                                    }
                                                    if (value !== 0) {
                                                        const trimmedValue = parseInt(e.target.value.replace(/^0+/g, ''));
                                                        setValue('placementsPerMonth', trimmedValue);
                                                    }
                                                    handleInputChange(e);
                                                }}
                                                onKeyDown={(e: any) => {
                                                    if (e.key === '+' || e.key === '-') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                error={!!validationError.placementsPerMonth}
                                                helperText={validationError.placementsPerMonth}
                                                onChange={handleInputChange}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        fontSize: '0.8rem'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div>
                                        {formData.hireType !== 'fulltime' ?
                                             <>
                                                 <div className='my-3'>
                                                     <Divider/>
                                                 </div>
                                                 <Typography
                                                     variant="subtitle1"
                                                     fontWeight="bold"
                                                     gutterBottom
                                                     color="darkslategray"
                                                     className="mb-0"
                                                 >
                                                     Why do you want to use AI tool like JobTalk?
                                                 </Typography>
                                                 <RadioGroup
                                                     {...register('aiReason', {required: true})}
                                                     onChange={(e) => handleInputChange(e)}>


                                                     <FormControlLabel
                                                         value="increaseRevenue"
                                                         control={<Radio/>}
                                                         checked={formData?.aiReason === 'increaseRevenue'}
                                                         label={formData?.hireType === 'contractors' ? "I want to increase my revenue and profits and not reduce my # of recruiters." : "I want to see increased placements with same # of recruiters."}
                                                         sx={{color: 'darkslategray', display: 'flex', marginRight: '0',}}
                                                     />


                                                     <FormControlLabel
                                                         value="reduceCost"
                                                         control={<Radio/>}
                                                         checked={formData?.aiReason === 'reduceCost'}
                                                         label={formData?.hireType === 'contractors' ? "I want to reduce recruiting cost and increase revenues also." : 'I want to increase placements and reduce # of recruiters'}
                                                         sx={{color: 'darkslategray', display: 'flex', marginRight: '0',}}
                                                     />


                                                 </RadioGroup>
                                             </>
                                            : null
                                        }
                                        <div className='my-3'>
                                            <Divider/>
                                        </div>
                                        <div>
                                            <Grid container>
                                                <Grid item xs={12} md={10}>
                                                    <Typography variant="body1" color="darkslategray" fontWeight="bold">
                                                        How much you are willing to pay for JobTalk per recruiter, per
                                                        month?
                                                    </Typography>
                                                    <Slider
                                                        size="small"
                                                        defaultValue={formData.jobTalkCostPerRecruiter}
                                                        aria-label="Small"
                                                        valueLabelDisplay="auto"
                                                        onChange={(e, value: any) => {
                                                            setFormData((prevFieldValue) => ({
                                                                ...prevFieldValue,
                                                                'jobTalkCostPerRecruiter': value
                                                            }));
                                                        }}
                                                        value={formData.jobTalkCostPerRecruiter}
                                                        min={180}
                                                        max={2000}
                                                        tabIndex={-1}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField
                                                        className={'input-number-no-arrow'}
                                                        size="small"
                                                        fullWidth
                                                        value={formData.jobTalkCostPerRecruiter}
                                                        defaultValue={180}
                                                        placeholder="jobTalk cost per recruiter"
                                                        {...register('jobTalkCostPerRecruiter', {required: true})}
                                                        InputProps={{
                                                            type: 'number',
                                                            inputProps: {
                                                                min: 180,
                                                                max: 2000,
                                                                style: {textAlign: 'right'},
                                                                pattern: '^[0-9]+$'
                                                            },
                                                        }}
                                                        onBlur={(e: any) => {
                                                            let value = parseInt(e.target.value);
                                                            if (value < 180 || !value) {
                                                                setValue('jobTalkCostPerRecruiter', 180)
                                                            } else if (value > 2000) {
                                                                setValue('jobTalkCostPerRecruiter', 2000)
                                                            }
                                                            if (value !== 0) {
                                                                const trimmedValue = parseInt(e.target.value.replace(/^0+/g, ''));
                                                                setValue('jobTalkCostPerRecruiter', trimmedValue);
                                                            }
                                                            handleInputChange(e);
                                                        }}
                                                        onKeyDown={(e: any) => {
                                                            if (e.key === '+' || e.key === '-') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        error={!!validationError.jobTalkCostPerRecruiter}
                                                        helperText={validationError.jobTalkCostPerRecruiter}
                                                        onChange={handleInputChange}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                fontSize: '0.8rem'
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>

                                    </div>
                                </div>
                            )}


                        </Box>
                    </div>

                    {isFormSubmitted &&
                        <div
                            className="w-full lg:animate-moveLeft pb-1 lg:w-[70%] lg:h-[calc(100vh_-_120px)] mb-[-4px] lg:overflow-y-auto mt-5 lg:mt-0 lg:px-3">
                            <div className="w-full">
                                <CalcOutput calculatedData={yearList} formData={formData}/>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </ThemeProvider>
    );
};

export default CalcPage;
