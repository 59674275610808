import {
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow, Typography, styled, tableCellClasses, TableCell
} from "@mui/material";
import React, {useEffect, useState} from "react";
import CalcTable from "./CalcTable";

interface YearData {
    contractRecruiters: number;
    costPerRecruiter?: number;
    contractPlacements: number;
    recruitersCostSavings?: number;
    totalExtraProfit?: number;
    netProfit: number;
    cost: number;
    monthData?: any;
    placementsPerMonth?: any;
    rcs?: any;
}

interface CalculatedData {
    yearData: YearData[];
    totalData: Omit<YearData, 'contractRecruiters'>;
    averageData: Omit<YearData, 'contractRecruiters'>;
}

const CalcOutput = (props: any) => {

    const [calculatedData, setCalculatedData] = useState<CalculatedData>({
        yearData: [],
        totalData: {contractPlacements: 0, netProfit: 0, cost: 0, recruitersCostSavings: 0, totalExtraProfit: 0, rcs: 0},
        averageData: {contractPlacements: 0, netProfit: 0, cost: 0, recruitersCostSavings: 0, totalExtraProfit: 0, rcs: 0},
    });

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        currency: 'USD',
        minimumFractionDigits: 0,
    });


    const {yearData, totalData, averageData} = calculatedData;
    const formatNumber = (value: number, minFraction = 0, maxFraction = 0, locale: string = 'en-US') => {
        return new Intl.NumberFormat(locale, {
            maximumFractionDigits: maxFraction,
            minimumFractionDigits: minFraction,
        }).format(value);
    };

    const contractPlacementsCalculation = (year: any) => {
        return Number((year.reduce((sum: any, month: any) => sum + Math.round(month.increasePlacement), 0)).toFixed(0))
    }
    const netProfitCalculation = (year: any) => {
        return year.reduce((sum: any, month: any) => sum + month.increaseProfitAdded, 0)
    }
    const recruitersCostSavingsCalculation = (year: any) => {
        return Number((year.reduce((sum: any, month: any) => sum + (month.recruitersCostSavings || 0), 0)).toFixed(0))
    }
    const totalExtraProfitCalculation = (year: any) => {
        return Number((year.reduce((sum: any, month: any) => sum + (month.totalExtraProfit || 0), 0)).toFixed(0))
    }


    useEffect(() => {
        const yearData = props.calculatedData.map((year: any[]) => {
            return {
                monthData: year,
                placementsPerMonth: props.formData.placementsPerMonth,
                costPerRecruiter: props.formData.recruitersCount * props.formData.jobTalkCostPerRecruiter,
                contractRecruiters: props.formData.recruitersCount,
                contractPlacements: contractPlacementsCalculation(year),
                netProfit: netProfitCalculation(year),
                cost: year.reduce((sum: any, month: any) => sum + (props.formData.recruitersCount * props.formData.jobTalkCostPerRecruiter), 0),
                rcs:  Number((year.reduce((sum: any, month: any) => sum + (month.recruitersCostSavings || 0), 0)).toFixed(0)),
                recruitersCostSavings: recruitersCostSavingsCalculation(year),
                totalExtraProfit: totalExtraProfitCalculation(year),
            };
        });

        const totalData = yearData.reduce(
            (acc: { contractPlacements: any; netProfit: any; cost: any; recruitersCostSavings: any; totalExtraProfit: any, rcs: any }, year: {
                contractPlacements: any;
                netProfit: any;
                cost: any;
                recruitersCostSavings: any;
                totalExtraProfit: any;
                rcs: any;
            }) => {
                acc.contractPlacements += year.contractPlacements;
                acc.netProfit += year.netProfit;
                acc.cost += year.cost;
                acc.recruitersCostSavings += year.recruitersCostSavings;
                acc.totalExtraProfit += year.totalExtraProfit;
                acc.rcs += year.rcs;
                return acc;
            },
            { contractPlacements: 0, netProfit: 0, cost: 0, recruitersCostSavings: 0, totalExtraProfit: 0, rcs: 0 }
        );


        const averageData = {
            contractPlacements: Number((totalData.contractPlacements / 3).toFixed(0)),
            netProfit: Number((totalData.netProfit / 3).toFixed(0)),
            cost: Number((totalData.cost / 3).toFixed(0)),
            recruitersCostSavings: Number(Number(Number(totalData.recruitersCostSavings) / 3).toFixed(0)),
            totalExtraProfit: Number(Number(Number(totalData.totalExtraProfit) / 3).toFixed(0)),
            rcs: Number(Number(Number(totalData.rcs) / 3).toFixed(0)),
        };


        setCalculatedData({ yearData, totalData, averageData });
    }, [props.calculatedData, props.formData]);


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#9e9e9e',
            color: theme.palette.common.white,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingRight: theme.spacing(2),
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    
    return (
        <Container className='md:px-5' style={{ padding: 10 }}>
            <Box  className={`w-full bg-white px-2 p-lg-3 h-max`}>
                <div className="flex flex-col mb-3">
                    <Typography variant="h4" color="primary" align="center"> Projections for 3 Years </Typography>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" className={''}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell align="right"><strong>Year 1</strong></StyledTableCell>
                                <StyledTableCell align="right"><strong>Year 2</strong></StyledTableCell>
                                <StyledTableCell align="right"><strong>Year 3</strong></StyledTableCell>
                                <StyledTableCell align="right"><strong>Total</strong></StyledTableCell>
                                <StyledTableCell align="right"><strong>Average <br/> per year</strong></StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <StyledTableCell component="th" scope="row"> <strong>Contract Recruiters
                                    Count </strong></StyledTableCell>
                                <StyledTableCell align="right">{props.formData.recruitersCount}</StyledTableCell>
                                <StyledTableCell align="right">{props.formData.recruitersCount}</StyledTableCell>
                                <StyledTableCell align="right">{props.formData.recruitersCount}</StyledTableCell>
                                <StyledTableCell align="right">-</StyledTableCell>
                                <StyledTableCell align="right">-</StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <StyledTableCell component="th" scope="row"> <strong>Number of more contract
                                    placements</strong></StyledTableCell>
                                <StyledTableCell align="right">{formatNumber(yearData[0]?.contractPlacements)}</StyledTableCell>
                                <StyledTableCell align="right">{formatNumber(yearData[1]?.contractPlacements)}</StyledTableCell>
                                <StyledTableCell align="right">{formatNumber(yearData[2]?.contractPlacements)}</StyledTableCell>
                                <StyledTableCell align="right">{formatNumber(totalData?.contractPlacements)}</StyledTableCell>
                                <StyledTableCell align="right">{formatNumber(averageData?.contractPlacements)}</StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <StyledTableCell component="th" scope="row"> <strong>Extra Net Profit Generated from more
                                    contractors</strong></StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(yearData[0]?.netProfit)}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(yearData[1]?.netProfit)}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(yearData[2]?.netProfit)}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(totalData?.netProfit)}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(averageData?.netProfit)}</StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <StyledTableCell component="th" scope="row"> <strong>Cost of JobTalk
                                    usage</strong></StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(yearData[0]?.cost)}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(yearData[1]?.cost)}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(yearData[2]?.cost)}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(totalData?.cost)}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormatter.format(averageData?.cost)}</StyledTableCell>
                            </StyledTableRow>

                            {props.formData.aiReason === 'reduceCost' && props.formData.hireType === 'contractors' ?
                                <>
                                    <StyledTableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <StyledTableCell component="th" scope="row"> <strong>Recruiter Cost
                                            Saving</strong></StyledTableCell>
                                        <StyledTableCell align="right">{currencyFormatter.format(yearData[0]?.rcs)}</StyledTableCell>
                                        <StyledTableCell align="right">{currencyFormatter.format(yearData[1]?.rcs)}</StyledTableCell>
                                        <StyledTableCell align="right">{currencyFormatter.format(yearData[2]?.rcs)}</StyledTableCell>
                                        <StyledTableCell align="right">{currencyFormatter.format(totalData?.rcs)}</StyledTableCell>
                                        <StyledTableCell align="right">{currencyFormatter.format(averageData?.rcs)}</StyledTableCell>
                                    </StyledTableRow>
                                </> : null
                            }


                            <StyledTableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <StyledTableCell component="th" scope="row"><strong>NET Extra Income</strong> </StyledTableCell>
                                <StyledTableCell
                                    align="right"><strong>{currencyFormatter.format(yearData[0]?.netProfit - yearData[0]?.cost +
                                    (props.formData.aiReason === 'reduceCost' && props.formData.hireType === 'contractors' ? yearData[0]?.rcs : 0)
                                    )}</strong></StyledTableCell>
                                <StyledTableCell
                                    align="right"><strong>{currencyFormatter.format(yearData[1]?.netProfit - yearData[1]?.cost +
                                    (props.formData.aiReason === 'reduceCost' && props.formData.hireType === 'contractors' ? yearData[1]?.rcs : 0)
                                )}</strong></StyledTableCell>
                                <StyledTableCell
                                    align="right"><strong>{currencyFormatter.format(yearData[2]?.netProfit - yearData[2]?.cost +
                                    (props.formData.aiReason === 'reduceCost' && props.formData.hireType === 'contractors' ? yearData[2]?.rcs : 0)
                                )}</strong></StyledTableCell>
                                <StyledTableCell
                                    align="right"><strong>{currencyFormatter.format(totalData?.netProfit - totalData?.cost +
                                    (props.formData.aiReason === 'reduceCost' && props.formData.hireType === 'contractors' ? totalData?.rcs : 0)
                                )}</strong></StyledTableCell>
                                <StyledTableCell
                                    align="right"><strong>{currencyFormatter.format(averageData?.netProfit - averageData?.cost +
                                    (props.formData.aiReason === 'reduceCost' && props.formData.hireType === 'contractors' ? averageData?.rcs : 0)
                                )}</strong></StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Box  className={`w-full bg-white px-2 p-lg-3 h-max`}>
                <CalcTable yearData={yearData}
                           formData={props.formData}
                           StyledTableCell={StyledTableCell}
                           StyledTableRow={StyledTableRow}/>
            </Box>

        </Container>
    );
}

export default CalcOutput;
