import React from 'react';
import { FiPhone, FiMail, FiMessageSquare, FiSearch, FiUserCheck, FiPieChart } from 'react-icons/fi';
import DemoCall from "../components/DemoCall";

const LandingPage: React.FC = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white">
            {/* Navbar */}
            <nav className="bg-white shadow-md">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex-shrink-0 flex items-center">
                            <span className="text-2xl font-bold text-blue-600">JobTalk.ai</span>
                        </div>
                        <div className="flex items-center">
                            <a href="/" className="text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md text-sm font-medium">Features</a>
                            <a href="/" className="text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md text-sm font-medium">Pricing</a>
                            <a href="/" className="text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md text-sm font-medium">Contact</a>
                            <a href="/" className="ml-4 bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700">Get Started</a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold text-gray-900 text-center">
                    Your Autonomous AI Recruiter
                </h1>
                <p className="mt-6 text-xl text-gray-600 text-center max-w-3xl mx-auto">
                    JobTalk.ai revolutionizes recruitment by automating candidate sourcing, outreach, and evaluation. Find the perfect candidates faster and more efficiently than ever before.
                </p>
                <div className="mt-10 flex justify-center">
                    <DemoCall  onSuccess={() => {}}/>
                </div>
            </header>

            {/* Features Section */}
            <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">How JobTalk.ai Works</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <FeatureCard
                        icon={<FiSearch className="w-8 h-8 text-blue-600" />}
                        title="Candidate Sourcing"
                        description="Our AI scours multiple platforms to find the best candidates for your job openings."
                    />
                    <FeatureCard
                        icon={<FiPhone className="w-8 h-8 text-blue-600" />}
                        title="AI Calls"
                        description="Conduct initial screenings through AI-powered phone calls, saving your team valuable time."
                    />
                    <FeatureCard
                        icon={<FiMail className="w-8 h-8 text-blue-600" />}
                        title="Automated Outreach"
                        description="Engage candidates through personalized emails and SMS messages."
                    />
                    <FeatureCard
                        icon={<FiMessageSquare className="w-8 h-8 text-blue-600" />}
                        title="Conversation Tracking"
                        description="Keep all candidate interactions organized and easily accessible."
                    />
                    <FeatureCard
                        icon={<FiUserCheck className="w-8 h-8 text-blue-600" />}
                        title="Technical Interviews"
                        description="Conduct in-depth technical assessments to evaluate candidates' skills."
                    />
                    <FeatureCard
                        icon={<FiPieChart className="w-8 h-8 text-blue-600" />}
                        title="Candidate Evaluation"
                        description="Get comprehensive reports on each candidate based on their interactions and assessments."
                    />
                </div>
            </section>

            {/* CTA Section */}
            <section className="bg-blue-600 py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-3xl font-extrabold text-white mb-4">Ready to Transform Your Recruitment Process?</h2>
                    <p className="text-xl text-blue-100 mb-8">Join the companies already benefiting from JobTalk.ai's autonomous recruiting system.</p>
                    <a href="/" className="bg-white text-blue-600 px-8 py-3 rounded-md text-lg font-medium hover:bg-blue-50 transition duration-300">
                        Get Started Today
                    </a>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-gray-800 text-white py-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <div className="mb-4 md:mb-0">
                            <span className="text-2xl font-bold">JobTalk.ai</span>
                        </div>
                        <div className="flex space-x-6">
                            <a href="/" className="hover:text-blue-300">Privacy Policy</a>
                            <a href="/" className="hover:text-blue-300">Terms of Service</a>
                            <a href="/" className="hover:text-blue-300">Contact Us</a>
                        </div>
                    </div>
                    <div className="mt-8 text-center text-gray-400 text-sm">
                        &copy; 2023 JobTalk.ai. All rights reserved.
                    </div>
                </div>
            </footer>
        </div>
    );
};

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => {
    return (
        <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-full mb-4">
                {icon}
            </div>
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    );
};

export default LandingPage;
