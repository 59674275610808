import { Tabs, Tab, Box, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { useState} from 'react';

function TabPanel(props: any) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
};
}

const formatNumber = (value: number, minFraction = 0, maxFraction = 0, locale: string = 'en-US') => {
    return new Intl.NumberFormat(locale, {
        maximumFractionDigits: maxFraction,
        minimumFractionDigits: minFraction,
    }).format(value);
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 0,
});

export default function CalcTable(props: any) {
    const StyledTableRow = props.StyledTableRow || '';
    const StyledTableCell = props.StyledTableCell || '';

    const [value, setValue] = useState(0);

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };

    /*const camelCaseLabel = (label: string) => {
        return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    }*/

    return (
        <Box sx={{width: '100%'}} className={'bg-gray-50 mt-5 rounded-md'}>
            <TableContainer component={Paper}>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                sx={{ textTransform: 'capitalize' }}
                aria-label="yearly data tabs full width primary"
                variant="fullWidth"
            >
                {props.yearData.map((_: any, index: number) => (
                    <Tab
                        sx={{ textTransform: 'capitalize',
                            fontWeight: '700'}}
                        label={`Year ${index + 1}`}
                        key={index}
                        {...a11yProps(index)}
                    />
                ))}
            </Tabs>
            {props.yearData.map((row: any, index: number) => (
                <TabPanel value={value} index={index} key={index} sx={{padding: 0}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                        <TableBody sx={{ maxHeight: 500, overflowX: 'auto' }}>
                            {/*<StyledTableRow>
                                <StyledTableCell colSpan={6} scope="row" align="center">
                                    <h6 className="sm:text-2xl font-bold inline bg-gradient-to-r from-blue-400 via-sky-600 to-blue-800 bg-clip-text font-display tracking-tight text-transparent text-center">
                                        Detailed Projection for Year {index + 1}
                                    </h6>
                                </StyledTableCell>
                            </StyledTableRow>*/}
                            <StyledTableRow>
                                {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ? (
                                    <>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="right"><strong>Red%</strong></StyledTableCell>
                                        <StyledTableCell align="right"><strong>Rec</strong></StyledTableCell>
                                        <StyledTableCell align="right"><strong>Recruiter Cost <br /> Saving</strong></StyledTableCell>
                                        <StyledTableCell align="right"><strong>Prod.Increase</strong></StyledTableCell>
                                        <StyledTableCell align="right"><strong>Increase profit added</strong></StyledTableCell>
                                    </>
                                ) : (
                                    <>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="right"><strong>Prod.Increase</strong></StyledTableCell>
                                        <StyledTableCell align="right"><strong># More <br /> Placements</strong></StyledTableCell>
                                        <StyledTableCell align="right"><strong>Increase profit <br /> added</strong></StyledTableCell>
                                        <StyledTableCell align="right"><strong>Per Recruiter</strong></StyledTableCell>
                                        <StyledTableCell align="right"><strong>JT Cost to client</strong></StyledTableCell>
                                    </>
                                )}
                            </StyledTableRow>
                            {row.monthData && row.monthData.map((month: any, monthIndex: number) => (
                                <StyledTableRow key={monthIndex}>
                                    <StyledTableCell component="th" scope="row">Month {month.month}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors')
                                            ? formatNumber(month.reductionInRecruiters, 2, 2)
                                            : formatNumber(month.increaseInProduction, 2, 2)
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors')
                                            ? formatNumber(month.reducedRec)
                                            : formatNumber(month.increasePlacement)
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors')
                                            ? currencyFormatter.format(month.recruitersCostSavings)
                                            : currencyFormatter.format(month.increaseProfitAdded)
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors')
                                            ? formatNumber(month.increaseInProduction, 2, 2)
                                            : currencyFormatter.format(Math.round(month.increaseProfitAdded / row.contractRecruiters))
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors')
                                            ? currencyFormatter.format(month.increaseProfitAdded)
                                            : currencyFormatter.format(Number(row.costPerRecruiter))
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            <StyledTableRow>
                                <StyledTableCell colSpan={4} scope="row">
                                    *at the end of year {index + 1}, a recruiter who was making
                                    <strong> {row.placementsPerMonth}</strong> placement a month will now make
                                    <strong> {Number(row.monthData[11].increaseInProduction) + Number(row.placementsPerMonth)}</strong> placements
                                    a month
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow sx={{ backgroundColor: 'lightgrey' }}>
                                <StyledTableCell component="th" scope="row"><strong>Year {index + 1}</strong></StyledTableCell>
                                <StyledTableCell align="right">-</StyledTableCell>
                                {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors')
                                    ? <StyledTableCell align="right">-</StyledTableCell>
                                    : <StyledTableCell align="right"><strong>{formatNumber(row.contractPlacements)}</strong></StyledTableCell>
                                }
                                <StyledTableCell align="right">
                                    {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors')
                                        ? <strong>{formatNumber(Number(row.recruitersCostSavings))}</strong>
                                        : <strong>{formatNumber(Number(row.netProfit))}</strong>
                                    }
                                </StyledTableCell>
                                <StyledTableCell align="right">-</StyledTableCell>
                                {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors')
                                    ? <StyledTableCell align="right"><strong>{currencyFormatter.format(row.netProfit)}</strong></StyledTableCell>
                                    : <StyledTableCell align="right"><strong>{formatNumber(row.cost)}</strong></StyledTableCell>
                                }
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TabPanel>
            ))}
            </TableContainer>
        </Box>
    );
}
