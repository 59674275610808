import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import { FiPhone, FiUser, FiGlobe, FiUserCheck } from 'react-icons/fi';
import PhoneInputComponent from '../utils/phoneNumberInput';
import { toastMessage } from '../utils/common-functions';

type FormValues = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    language: string;
    accent?: string;
    gender: string;
};

interface DemoCallProps {
    onSuccess: () => void;
}

const DemoCall: React.FC<DemoCallProps> = ({ onSuccess }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch
    } = useForm<FormValues>({
        defaultValues: {
            language: 'english'
        }
    });

    const [campaignType, setCampaignType] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [otpModelOpen, setOtpModelOpen] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const otpRefs = useRef<any>(Array(6).fill(null));
    const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
    const [timer, setTimer] = useState<number>(120);
    const [resendActive, setResendActive] = useState<boolean>(false);

    const watchLanguage = watch('language');

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (otpModelOpen && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setResendActive(true);
        }
        return () => clearInterval(interval);
    }, [timer, otpModelOpen]);

    const ensureStartsWithPlus = (inputString: string) => {
        return inputString.startsWith('+') ? inputString : '+' + inputString;
    };

    const verifyOtp = async () => {
        setShowLoader(true);
        if (!formData.phoneNumber) {
            setShowLoader(false);
            toastMessage('error', 'Please Add Phone Number');
            return;
        }

        if (otp.join("").length < 6) {
            setShowLoader(false);
            toastMessage('error', 'Enter Valid Otp');
            return;
        }

        const otpData = {
            otp: otp.join(""),
            phoneNumber: ensureStartsWithPlus(formData.phoneNumber)
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/otp/verify-otp`, otpData);
            setShowLoader(false);

            if (response.status === 200 && response.data.data.otpVerified) {
                const campaignData = {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    phoneNumber: ensureStartsWithPlus(formData.phoneNumber),
                    campaignType: campaignType,
                    language: formData.language,
                    gender: formData.gender,
                    accent: formData.accent,
                };

                const callResponse = await axios.post(`${process.env.REACT_APP_API_URL}/calls/call-to-demo-campaign`, campaignData);

                if (callResponse.status === 200) {
                    toastMessage('success', 'Expect call from JobTalk soon…');
                    onSuccess();
                    reset();
                } else {
                    toastMessage('error', callResponse.data);
                }
            } else {
                toastMessage('error', response.data.message);
            }
        } catch (error) {
            console.error(error);
            toastMessage('error');
        }
        setOtpModelOpen(false);
    };

    const handleClose = () => {
        setOtpModelOpen(false);
        setTimer(120);
        setResendActive(false);
    };

    const handleResendOTP = async () => {
        if (!formData.phoneNumber) {
            toastMessage('error', 'Please Add Phone Number first');
            return;
        }
        setShowLoader(true);
        setOtp(Array(6).fill(''));
        setTimer(120);
        setResendActive(false);

        const otpData = {
            phoneNumber: ensureStartsWithPlus(formData.phoneNumber),
            campaignType: campaignType
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/otp/send-otp`, otpData);
            setShowLoader(false);
            if (response && response.status === 200) {
                toastMessage('success', 'OTP resent Successfully');
            } else {
                toastMessage('error');
            }
        } catch (e) {
            setShowLoader(false);
            console.error(e);
            toastMessage('error');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value && index < otp.length - 1 && otpRefs.current) {
                otpRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0 && otpRefs.current) {
            otpRefs.current[index - 1]?.focus();
        }
    };

    const onSubmitForm: SubmitHandler<FormValues> = async (data: FormValues) => {
        setShowLoader(true);
        if (!data.phoneNumber) {
            setShowLoader(false);
            toastMessage('error', 'Please Add Phone Number');
            return;
        }
        setFormData(data);
        setOtp(Array(6).fill(''));
        setTimer(120);
        setResendActive(false);

        const otpData = {
            phoneNumber: ensureStartsWithPlus(data.phoneNumber),
            campaignType: campaignType
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/otp/send-otp`, otpData);
            setShowLoader(false);
            if (response.data) {
                toastMessage('success', 'OTP sent to your phone number.');
                setOtpModelOpen(true);
            } else {
                toastMessage('error');
            }
        } catch (e) {
            setShowLoader(false);
            console.error(e);
            toastMessage('error');
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-xl p-6 max-w-2xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-900 mb-6 text-center">Try for yourself</h2>
            <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First
                            name</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <FiUser className="h-5 w-5 text-gray-400"/>
                            </div>
                            <input
                                type="text"
                                {...register('firstName', {required: true})}
                                className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${errors.firstName ? 'border-red-500' : ''}`}
                                placeholder="John"
                            />
                        </div>
                        {errors.firstName && <p className="mt-1 text-sm text-red-600">First name is required</p>}
                    </div>
                    <div>
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last name</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <FiUser className="h-5 w-5 text-gray-400"/>
                            </div>
                            <input
                                type="text"
                                {...register('lastName', {required: true})}
                                className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${errors.lastName ? 'border-red-500' : ''}`}
                                placeholder="Doe"
                            />
                        </div>
                        {errors.lastName && <p className="mt-1 text-sm text-red-600">Last name is required</p>}
                    </div>
                </div>

                <div>
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone
                        number</label>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                            required: 'Phone number is required',
                            validate: (value) => {
                                const phoneNumberLength = value.replace(/\D/g, '').length;
                                return phoneNumberLength >= 10 || 'Phone number should be at least 10 digits';
                            }
                        }}
                        render={({field: {onChange, value}, fieldState: {error}}) => (
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FiPhone className="h-5 w-5 text-gray-400"/>
                                </div>
                                <PhoneInputComponent
                                    country="us"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    inputClass={`block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${errors.phoneNumber ? 'border-red-500' : ''}`}
                                />
                            </div>
                        )}
                    />
                    {errors.phoneNumber && <p className="mt-1 text-sm text-red-600">{errors.phoneNumber.message}</p>}
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="language" className="block text-sm font-medium text-gray-700">Language</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <FiGlobe className="h-5 w-5 text-gray-400"/>
                            </div>
                            <select
                                {...register('language', {required: 'Language is required'})}
                                className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${errors.language ? 'border-red-500' : ''}`}
                            >
                                <option value="english">English</option>
                                <option value="spanish">Spanish</option>
                            </select>
                        </div>
                        {errors.language && <p className="mt-1 text-sm text-red-600">{errors.language.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <FiUserCheck className="h-5 w-5 text-gray-400"/>
                            </div>
                            <select
                                {...register('gender', {required: 'Gender is required'})}
                                className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${errors.gender ? 'border-red-500' : ''}`}
                            >
                                <option value="" disabled>Select gender</option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                            </select>
                        </div>
                        {errors.gender && <p className="mt-1 text-sm text-red-600">{errors.gender.message}</p>}
                    </div>
                </div>

                {watchLanguage === 'english' && (
                    <div>
                        <label htmlFor="accent" className="block text-sm font-medium text-gray-700">Accent</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <FiGlobe className="h-5 w-5 text-gray-400"/>
                            </div>
                            <select
                                {...register('accent')}
                                className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${errors.accent ? 'border-red-500' : ''}`}
                            >
                                <option value="american">American</option>
                                <option value="british">British</option>
                            </select>
                        </div>
                        {errors.accent && <p className="mt-1 text-sm text-red-600">{errors.accent.message}</p>}
                    </div>
                )}

                <div className="flex justify-center space-x-4 mt-6">
                    <button
                        type="button"
                        disabled={showLoader}
                        onClick={() => {
                            setCampaignType('python');
                            handleSubmit(onSubmitForm)();
                        }}
                        className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Python Developer Call
                    </button>
                    <button
                        type="button"
                        disabled={showLoader}
                        onClick={() => {
                            setCampaignType('java');
                            handleSubmit(onSubmitForm)();
                        }}
                        className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Java Developer Call
                    </button>
                </div>
            </form>

            {showLoader && (
                <div
                    className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            )}

            {otpModelOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <div className="mt-3 text-center">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Enter OTP</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500">
                                    We've sent a code to your phone. It will expire in 2 minutes.
                                </p>
                                <div className="flex justify-center space-x-2 mt-4">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength={1}
                                            value={digit}
                                            onChange={(e) => handleChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                            ref={(ref) => otpRefs.current[index] = ref}
                                            className="w-10 h-10 text-center border-2 rounded"
                                        />
                                    ))}
                                </div>
                                {timer > 0 ? (
                                    <p className="text-sm text-gray-500 mt-4">
                                        Resend OTP
                                        in {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                                    </p>
                                ) : (
                                    <button
                                        onClick={handleResendOTP}
                                        disabled={!resendActive}
                                        className="text-sm text-blue-600 mt-4 hover:text-blue-800"
                                    >
                                        Resend OTP
                                    </button>
                                )}
                            </div>
                            <div className="items-center px-4 py-3">
                                <button
                                    onClick={verifyOtp}
                                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                                >
                                    Verify OTP
                                </button>
                                <button
                                    onClick={handleClose}
                                    className="mt-3 px-4 py-2 bg-gray-100 text-gray-700 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DemoCall;
